<template>
  <div class="d-flex flex-column flex-grow-1 pr-4">
    <div class="d-flex align-center">
      <v-text-field
        :label="$t('t.Subject')"
        :value="subject"
        @input="$emit('update:subject', $event)"
        :rules="rules"
        :counter="subjectMaxLength"
      />
      <template v-if="dense"><v-btn
          class="ml-1"
          icon
          @click="$emit('update:orderOldFirst', !orderOldFirst)"
        >
          <icon-tooltip
            :icon-name="orderOldFirst ? 'i.SortClockAsc' : 'i.SortClockDesc'"
            :tooltip-text="orderOldFirst ? $t('t.OldestFirst') : $t('t.NewestFirst')"
          />
        </v-btn>
      </template>
    </div>
    <div
      class="d-flex overflow-y-hidden"
      :class="{'flex-column': orderOldFirst, 'flex-column-reverse': !orderOldFirst}"
    >
      <div
        class="d-flex overflow-y-auto mt-1 mb-1 pr-2"
        :class="{'flex-column': orderOldFirst, 'flex-column-reverse': !orderOldFirst}"
      >
        <topic-comment-card
          :userId="createBy"
          :outlined="true"
          :comment.sync="document.description"
          :create-at="document.createAt"
          :dense="dense"
          :update-at="document.updateAt"
          :placeholder="$t('t.TopicDescriptionPlaceholder')"
          :show-edit-button="false"
          :show-action-button="false"
          :show-create-by-label="!documentIsNew"
          :show-user-information="!documentIsNew"
          :filled="documentIsNew"
          :rows="2"
          :comment-color="commentColor"
          required
        />
        <topic-comment-list
          v-if="!documentIsNew"
          class="d-flex"
          :class="{'flex-column': orderOldFirst, 'flex-column-reverse': !orderOldFirst}"
          :comments="document.comments"
          :dense="dense"
          :topic-id="document.id"
          :order-up.sync="orderOldFirst"
          ref="comment-list"
        />
      </div>
      <topic-comment
        class="mb-n2"
        v-if="
        !documentIsNew
        &&
        !document.closedAt"
        :topic-id="document.id"
        :rows="3"
        :filled="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TopicCommentCard: () => import('./topic-comment-card'),
    TopicComment: () => import('./topic-comment'),
    TopicCommentList: () => import('./topic-comment-list'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    commentColor () {
      return this.documentIsNew ? '' : 'background-plain'
    },
    createBy () {
      return this.document.createBy ?? this.$store.getters.currentUser.id
    },
    rules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= this.subjectMaxLength
      ]
    },
    subjectMaxLength () {
      return 255
    }
  },
  watch: {
    'document.comments' (n, o) {
      if (n.length > o?.length) {
        this.$nextTick(() => this.$refs['comment-list'].$el.lastChild.scrollIntoView())
      }
    }
  },
  props: {
    dense: Boolean,
    document: { required: true, type: Object },
    documentIsNew: Boolean,
    orderOldFirst: Boolean,
    subject: String
  }
}
</script>
